<template>	
    <v-card outlined color="white" class="selevation-1 mt-3 mt-lg-5 mx-auto" max-width="500">
        <v-card-text>
            <v-layout row wrap align-center px-4 pt-2 pb-2>
                <v-flex grow pr-4 class="text-center">
                    <v-avatar size="100" color="secondary" class="action-item elevation-3" :tile="item.image !== null || previewImg !== null">
                        <v-img v-if="item.image || previewImg" cover @click="handleImgClick()" :src="previewImg || item.image" />
                        <v-icon v-else size="60" @click="pickFile" color="primary">fa fa-camera</v-icon>
                    </v-avatar>
                </v-flex>
                <v-flex grow class="text-center">
                    <v-btn @click.native="pickFile" :loading="uploading" small color="primary" class="px-5 mb-2">{{ (item.image || previewImg) ? 'Change Photo' : 'Add a Photo'}}</v-btn>
                    <br>
                    <v-btn @click.native="(item.image) ? deletePhoto=true : removePhoto()" text :loading="removing" v-if="previewImg !== null || item.image !== null" small color="error" outlined class="px-5 mb-2">Remove Photo</v-btn>
                </v-flex>
            </v-layout>
            <p class="body-2 text-center mt-6 px-4">Only image files (e.g. 'jpg', 'png', etc.) allowed.</p>
        </v-card-text>
        <input
            type="file"
            style="display: none"
            ref="imageBtn"
            accept="image/*"
            @change="onFilePicked"
        >
        <Confirm v-if="deletePhoto" title="Remove Your Photo" subtitle="Are you sure you want to delete your photo?" @closed="deletePhoto=false" @confirmed="removePhoto()" />
        <LearnPhotos @closed="learnMorePhotos=false" v-if="learnMorePhotos" />
    </v-card>
</template>
<script>

import { mapState, mapMutations } from 'vuex'

export default {
    name: 'UserPhoto',
    props: {
        item: {
            required: true,
            type: Object
        },
        naked: {
            required: false,
            type: Boolean,
            default: false
        }
    },
	data: () => ({
        thisUser: {},
        isCropping: false,
        blob: null,
        cropper: null,
        file: null,
        uploading: false,
        previewImg: null,
        deletePhoto: false,
        removing: false,
        formData: null,
        points: null,
        learnMorePhotos: false,

	}),
	components: {
		Confirm: () => import('@/components/common/Confirm'),
		LearnPhotos: () => import('@/components/common/LearnPhotos'),
	},
	computed: {
		...mapState(['user'])
	},
	methods: {
        ...mapMutations(['setUser']),
		initData(){
            this.thisUser = JSON.parse(JSON.stringify(this.user));
        },
        randString(){
            return '?' + Math.floor(Math.random() * 101);
        },
        handleImgClick(){
            if(!this.item.image){
                this.pickFile();
                return;
            }
            window.open(this.item.image, '_blank');
        },
        reset(){
            this.isCropping = false;
            this.file = null;
            this.blob = null;
            if(this.cropper){
                this.cropper.destroy();
            }
            this.cropper = null;
            if(this.$refs.imageBtn.files.length){
                this.$refs.imageBtn.type = 'text';
                this.$refs.imageBtn.type = 'file';
            }
        },
        discardPhoto(){
            this.reset();
            this.$emit('selected', null);
        },
        pickFile () {
            this.$refs.imageBtn.click()
        },
        // cropImage(image){
        //     this.blob = image;
        //     if(this.cropper){
        //         this.cropper.destroy();
        //     }
        //     this.cropper = new Croppie(document.getElementById('croparea'), {
        //         viewport: { width: 200, height: 200},
        //         boundary: { width: 300, height: 300},
        //     });
        //     this.cropper.bind({
        //         url: this.blob
        //     });
        // },
        savePhoto(){    
            // let _self = this;
            // this.cropper.result('blob', 'original', 'jpeg', 1, false).then(function(blob) {
            //     _self.blob = blob;
            //     _self.uploadPhoto();
            // });
            let crop = this.cropper.get();
            this.points = crop.points;
            this.uploadPhoto();
        },
        setFormImage(){
            fetch(this.blob)
            .then((response) => {
                return response.blob();
            }).then((blob) => {
                this.formData.append('image', blob);
                this.uploadPhoto(true);
            });
        },
        uploadPhoto(imageAdded){
            this.uploading = true;
            this.isCropping = false;

            if(!imageAdded){
                this.formData = new FormData();
                this.setFormImage();
                return;
            }

            let meta = {
                'points' : this.points
            };
            this.formData.append('meta', JSON.stringify(meta));

            let request = {
                endpoint: '/user/photo', 
                method: 'post',
                data: this.formData,
                apiKey: this.user.api_token || null,
                header: { 'Content-Type': 'multipart/form-data' },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status === 200){
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: (this.user.photo) ? 'Updated profile photo' : 'Added profile photo'
                    }});
                    this.setUser(res.data);
                    this.initData();
                }
            }).catch(()=>{

            }).finally(()=>{
                this.uploading = false;
                this.reset();
                this.$emit('saved', true);
            });
        },
        onFilePicked (e) {
            let image;
            this.file = e.target.files[0];
            this.previewImg = URL.createObjectURL(this.file);
            // this.isCropping = true;
            let fileReader = new FileReader();
            fileReader.onloadend = (e) => {
                image = e.target.result;
                this.blob = image;
                this.$emit('selected', this.blob);
            };
            fileReader.readAsDataURL(e.target.files[0]);
        },
        removePhoto(){
            this.previewImg = null;
            this.$emit('selected', null);
            this.$emit('removed');
            // this.removing = true;
            // let request = {
            //     endpoint: '/user/photo', 
            //     method: 'delete',
            //     data: {},
            //     apiKey: this.user.api_token || null
            // };
            // this.$appFn.callApi(request).then((res)=>{
            //     if(res.status === 200){
            //         this.setUser(res.data);
            //         this.initData();
            //     }
            // }).catch(()=>{

            // }).finally(()=>{
            //     this.removing = false;
            // });
        },
        uuidv4() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            )
        },
        clearMsgs(){
        }
	},
	mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>