<template>
    <v-dialog :value="true" overlay-opacity="0.6" overlay-color="#000" @input="close()" max-width="500px">
        <v-card>
            <v-card-title class="py-2 dcard-title">
                <h3 class="headline mb-0 primary--text text-center">{{ title }}</h3>
                <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
                <v-divider />
                <v-container pa-0 py-6 px-3 fluid>
                    <v-layout row wrap> 
                        <v-flex xs12 px-4 text-xs-center v-if="subtitle">
                            <h4 class="subtitle-1 mt-2 lh-14 font-weight-medium secondary--text text-center">{{ subtitle }}</h4>
                        </v-flex>
                        <v-flex v-if="msg" xs12 px-4>
                            <p class="body-2 mt-2 text-center">{{ msg }}</p>
                        </v-flex>
                        <v-flex v-if="errMsg" xs12 px-4>
                            <p class="body-2 error--text mt-2 text-center">{{ errMsg }}</p>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions class="card-actions">
                <v-btn text outlined @click.native="close()">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="px-3" @click.native="confirm">{{ confirmBtnText }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {

  name: 'ConfirmModal',

    props: {
        title: {
            required: false,
            type: String,
            default: "Confirm Removal"
        },
        subtitle: {
            required: false,
            type: String,
            default: "Are you sure you want to remove this item?"
        },
        msg: {
            required: false,
            type: String
        },
        errMsg: {
            required: false,
            type: String
        },
        confirmBtnText: {
            required: false,
            type: String,
            default: "Confirm"
        }
    },

    computed: {
        
    },
    methods: {
        confirm(){
            this.$emit('confirmed', true);
            this.close();
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
    },
    watch: {
    }
}
</script>
